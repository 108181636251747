import React, { useRef, useState, MouseEvent } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { selectRoomData } from '../../redux/slices/dataSlice';
import PatientInfo from '../molecules/PatientInfo';
import ValueDisplay from '../molecules/ValueDisplay';
import BadgeComponent from '../molecules/Badge';
import PatientCardHeader from '../molecules/PatientCardHeader';
import ContextMenu from './PatientContextMenu';
import AssignPatientModal from './AssignPatient.Modal';
import { Patient, Badge } from '../../types';
import { VitalsColorKeys } from '../../theme/colors';
import { useLocation } from 'react-router-dom';

import { AiOutlineDisconnect } from "react-icons/ai";
import { GiMovementSensor } from "react-icons/gi";
import { FaWeightScale } from "react-icons/fa6";



interface PatientCardProps {
  sessionId?: string;
  patient: Patient;
  badge: Badge;
  deviceId: string;
  onOpenOverlay: (machine_id: string) => void;
}

const StyledCard = styled(Card)`
  cursor: pointer;
  min-width: 18rem;
  background-color: #000;
  color: #fff;
`;

function getTimeFromISOString(isoString: string): string {
  const date = new Date(isoString);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `Last Update: ${hours}:${minutes}:${seconds}`;
}

function parseBoolean(value: string | null): boolean {
  return !!(value?.toLowerCase() === 'true');
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function transformTemperature(temperature: number, imperial=false){
  let value = temperature;
  let symbol = "°C";
  if(imperial){
    symbol = "°F";
    value = (temperature * 9 / 5) + 32;
  }
  return {
    value, symbol
  }
}


function chooseRRValues(wrr: number | undefined, wrrValid: boolean, vrr: number, vrrValid: boolean) {
  let rr = -1, valid = false, rrType = 'none';
  if (wrrValid && (wrr && wrr > 0)) {
    rr = wrr;
    valid = true;
    rrType = "wrr"
  } else if (vrr && vrrValid) {
    // rr = vrr;
    valid = false;
    rrType = "vrr"
  }
  return {
    rr, valid, rrType
  }
}
const PatientCard: React.FC<PatientCardProps> = ({ sessionId, patient, badge, deviceId, onOpenOverlay }) => {
  const query = useQuery();
  const vitalsData = useSelector((state: RootState) => selectRoomData(state, deviceId));
  const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isSessionRunning, setIsSessionRunning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const useImperial: boolean = parseBoolean(query.get('imperial'));

  const cardRef = useRef<HTMLDivElement>(null);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (cardRef.current) {
      const rect = cardRef.current.getBoundingClientRect();
      setContextMenu({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    }
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const handleAssignPatient = (patient: any) => {
    setIsAssigned(true);
    // Handle patient assignment logic here
  };

  const handleStartSession = () => {
    if (isAssigned) {
      setIsSessionRunning(true);
    }
    handleCloseContextMenu();
  };

  const handleEndSession = () => {
    if (isSessionRunning) {
      setIsSessionRunning(false);
      setIsAssigned(false);
    }
    handleCloseContextMenu();
  };

  const handleAssignClick = () => {
    setShowModal(true);
    handleCloseContextMenu();
  };
  const meta = vitalsData.meta;

  const hrColor: VitalsColorKeys = "neongreen"
  const rrColor: VitalsColorKeys = "neonblue"
  const weighColor: VitalsColorKeys = "neonyellow"
  const tempColor: VitalsColorKeys = "white"
  const rrData = chooseRRValues(vitalsData?.rr, meta.wrrValid, meta.vrr, meta.vhrValid);
  console.log(vitalsData)
  const temperatureDisplay = transformTemperature(vitalsData.temp, useImperial)
  return (
    <>
      <StyledCard onContextMenu={handleContextMenu} ref={cardRef}>
        <PatientCardHeader patientId={patient.id} color={badge.color} />
        <Card.Body>
          <Row>
            <Col xs>
              <PatientInfo
                name={patient.name}
                phone={patient.phone}
                doctor={patient.doctor}
                species={patient.species}
              />
            </Col>
            <Col xs lg="4">
              <BadgeComponent number={badge.number} color={badge.color} location={badge.location} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <ValueDisplay
                value={vitalsData.hr}
                unit="BPM"
                displayColor={hrColor}
                gt={meta.gt}
                gt_value={vitalsData.gt.hr}
                gtValid={meta.shrValid}
                valid={meta.vhrValid}
                precision={0}
              />
            </Col>
            <Col>
              <ValueDisplay
                value={rrData.rr}
                unit="RR"
                displayColor={rrColor}
                gt={meta.gt}
                gtValid={meta.frrValid}
                gt_value={vitalsData.gt.rr}
                valid={rrData.valid}
                precision={0}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <ValueDisplay
                value={temperatureDisplay.value}
                unit={temperatureDisplay.symbol}
                displayColor={tempColor}
                small={true}
                valid={vitalsData.validTemp}
                precision={1}
              />
            </Col>
            <Col>
              <ValueDisplay
                value={+vitalsData?.weight.toFixed(1)}
                unit="kg"
                displayColor={weighColor}
                small={true}
                valid={vitalsData.weight > 2}
                precision={1}
              />
            </Col>
          </Row>
        </Card.Body>
        {contextMenu && (
          <ContextMenu
            position={contextMenu}
            onClose={handleCloseContextMenu}
            onAssignClick={handleAssignClick}
            onStart={handleStartSession}
            onEnd={handleEndSession}
            isAssigned={isAssigned}
            isSessionRunning={isSessionRunning}
            openHealthDataOverlay={() => {
              onOpenOverlay(deviceId)
            }}
          />
        )}
        <Card.Footer style={{
          textAlign: "right",
          opacity: .5
        }}>
          {vitalsData.meta.continuousData !== null && !vitalsData.meta.continuousData && <><AiOutlineDisconnect title='data is not continous' />&nbsp;</>}
          {vitalsData.meta.weightSettled !== null &&!vitalsData.meta.weightSettled && <><GiMovementSensor title='patient isnt still' />&nbsp;</>}
          {rrData.rrType === 'wrr' && <><FaWeightScale title='rr based on weight' />&nbsp;</>}
          {(vitalsData?.ts) ? getTimeFromISOString(vitalsData?.ts + "") : "--"}</Card.Footer>
      </StyledCard>
      <AssignPatientModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onAssign={handleAssignPatient}
      />
    </>
  );
};

export default PatientCard;
